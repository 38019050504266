import { Blocks } from "@libry-content/types";
// eslint-disable-next-line no-restricted-imports -- We avoid importing these directly except for this file
import nb from "date-fns/locale/nb";
import nn from "date-fns/locale/nn";

export const locales = ["nb", "nn"] as const;

export type LanguageCode = (typeof locales)[number];

export const isValidLanguageCode = (type: any): type is LanguageCode => ["nb", "nn"].includes(type);

export const validateLocaleOrFallbackToDefault = (value?: any): LanguageCode =>
  isValidLanguageCode(value) ? value : "nb";

export type FieldValue = boolean | string | Blocks | undefined;

export type LocalizedField<T = FieldValue> = Partial<Record<LanguageCode, T>>;

export const concatenateLocalizedStrings = (
  { separator } = { separator: " " },
  ...localizedStrings: LocalizedField<string>[]
): LocalizedField<string> =>
  localizedStrings
    .slice(1)
    .reduce((acc, { nb, nn }) => ({ nb: `${acc.nb}${separator}${nb}`, nn: `${acc.nn}${separator}${nn}` }), {
      nb: localizedStrings[0]?.nb ?? "",
      nn: localizedStrings[0]?.nn ?? "",
    });

const omit = (obj: object, omittedKey: string): object =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => omittedKey !== key));

export const isLocalizedField = (value: unknown): value is object =>
  typeof value === "object" && value != null && Object.keys(omit(value, "_type")).every(isValidLanguageCode);

export const LANGUAGES = {
  nb: { name: "Bokmål" },
  nn: { name: "Nynorsk" },
} as const;

// https://stackoverflow.com/a/55012175/1540547
export const LANGUAGE_CODES = Object.keys(LANGUAGES) as LanguageCode[];

export const DEFAULT_LANGUAGE_CODE: LanguageCode = "nb";

export const translateSanity = <T = FieldValue>(
  localizedObject: LocalizedField<T> | undefined,
  languageCode: LanguageCode
): T | undefined => {
  if (typeof localizedObject === "string") {
    console.error("Attempted to get localized text from a string:", localizedObject);
    return localizedObject;
  }

  return localizedObject?.[getLanguageCodeForSanityContent(localizedObject, languageCode)];
};

export const getLanguageCodeForSanityContent = <T = FieldValue>(
  localizedObject: LocalizedField<T> | undefined,
  prefferedLang: LanguageCode
): LanguageCode => {
  if (!localizedObject) return prefferedLang;
  if (prefferedLang in localizedObject) return prefferedLang;

  // Random fallback
  return LANGUAGE_CODES.find((lang) => lang in localizedObject) ?? prefferedLang;
};

export const localeFromLanguageCode: Record<LanguageCode, Locale> = { nb, nn };

/**
 * Example: localizedPortableTextGroq("body") -> "nb": pt::text(body.nb), "nn": pt::text(body.nn)
 */
export const localizedPortableTextGroq = (fieldName: string) =>
  LANGUAGE_CODES.map((code) => `"${code}": pt::text(${fieldName}.${code})`).join(", ");

const hasCanonicalLocalesFunction = (item: unknown): item is { getCanonicalLocales: (code: string) => string[] } =>
  typeof item === "object" && typeof (item as any)?.["getCanonicalLocales"] === "function";

const getIntlCanonicalLanguageCode = (languageCode: string): string | undefined => {
  if (!hasCanonicalLocalesFunction(Intl)) return undefined;

  // @ts-ignore -- not yet included in ts but mostly supported: https://github.com/microsoft/TypeScript/issues/29129
  const canonicalLanguageCodes = Intl.getCanonicalLocales(languageCode);
  return canonicalLanguageCodes?.[0];
};

export const getCanonicalLanguageCode = (languageCode: string | undefined): string | undefined => {
  if (!languageCode) return undefined;

  try {
    const canonicalLanguageCode = getIntlCanonicalLanguageCode(languageCode);
    if (canonicalLanguageCode) return canonicalLanguageCode;
  } catch {
    // No action
  }

  try {
    const locale = new Intl.Locale(languageCode);
    return locale.baseName;
  } catch {
    console.warn(`Attempted to get the canonical language code for ${languageCode}`);
    return undefined;
  }
};

/**
 * The standard language for catalogization is Norwegian bokmål, which means that some
 * publication fiels, e.g. description, are always given in this language.
 */
export const catalogueLanguageCode: LanguageCode = "nb";
