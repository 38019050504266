import { validateLocaleOrFallbackToDefault } from "@libry-content/localization";
import { AppProps } from "next/app";
// eslint-disable-next-line no-restricted-imports
import { Providers } from "../app/[locale]/Providers";
import ErrorBoundary from "../components/errorPages/ErrorBoundary";

type Props = AppProps;

const App = ({ Component, pageProps, router }: Props) => (
  <ErrorBoundary boundaryName="App" isFullPage>
    <Providers messages={pageProps.messages} locale={validateLocaleOrFallbackToDefault(router.query.locale)}>
      <Component {...pageProps} />
    </Providers>
  </ErrorBoundary>
);

export default App;
