"use client";

import { BiblioteksentralenProvider } from "@biblioteksentralen/react";
import { LanguageCode } from "@libry-content/localization";
import { Analytics } from "@vercel/analytics/react";
// eslint-disable-next-line no-restricted-imports
import { NextIntlClientProvider } from "next-intl";
import { ReactNode, useState } from "react";
import Testenvironmentbanner from "../../components/TestEnvironmentBanner";
import { EditInSanityContextProvider } from "../../components/editInSanity/EditInSanityContext";
import { StudioSOMEPreview } from "../../components/preview/StudioSOMEPreview";
import { PreviewContextProvider } from "../../components/preview/previewContext";
import { IntlMessages, commonNextIntlConfig } from "../../i18n";
import { PlausibleSetup } from "../../utils/analytics/Plausible";
import { customTheme } from "../../utils/customTheme";
import { FrokostseminarProvider } from "../../components/frokostseminar/Frokostseminar";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { storeFeatureFlags } from "@libry-content/common";
import { useLogDevInfo } from "../../utils/logDevInfo";

export const Providers = (props: { children: ReactNode; messages?: IntlMessages; locale: LanguageCode }) => {
  // storeFeatureFlags should run only once but before other useEffect hooks; useMount and useEffect are too late.
  // useLayoutEffect works but gives an error when running on server, so we use an initial value function with useState.
  useState(() => storeFeatureFlags());

  useLogDevInfo();

  return (
    <>
      <NextIntlClientProvider locale={props.locale} messages={props.messages} {...commonNextIntlConfig}>
        <PreviewContextProvider>
          <BiblioteksentralenProvider customTheme={customTheme}>
            <FrokostseminarProvider>
              <EditInSanityContextProvider>
                <PlausibleSetup />
                <Testenvironmentbanner />
                {props.children}
                <StudioSOMEPreview />
              </EditInSanityContextProvider>
            </FrokostseminarProvider>
          </BiblioteksentralenProvider>
        </PreviewContextProvider>
      </NextIntlClientProvider>
      <Analytics />
      <SpeedInsights sampleRate={0.05} />
    </>
  );
};
